// extracted by mini-css-extract-plugin
export var authorLine = "JoinLeadersTestimonials-module--authorLine--2RvnS";
export var authorName = "JoinLeadersTestimonials-module--authorName--JaLsU";
export var authorTitle = "JoinLeadersTestimonials-module--authorTitle--nBiEe";
export var buttonLinkClass = "JoinLeadersTestimonials-module--buttonLinkClass--phb1p";
export var emailInputClass = "JoinLeadersTestimonials-module--emailInputClass--FegAM";
export var emailInputLabelClass = "JoinLeadersTestimonials-module--emailInputLabelClass--uwaFv";
export var emailInputWrapperClass = "JoinLeadersTestimonials-module--emailInputWrapperClass--iSfiK";
export var generalLayoutContainer = "JoinLeadersTestimonials-module--generalLayoutContainer--gFb1X";
export var headerText = "JoinLeadersTestimonials-module--headerText--bK4+q";
export var joinInstitution = "JoinLeadersTestimonials-module--joinInstitution--uvJ5v";
export var joinInstitutionContainer = "JoinLeadersTestimonials-module--joinInstitutionContainer--FqRLg";
export var limitWidthOnLargeScreens = "JoinLeadersTestimonials-module--limitWidthOnLargeScreens--vmZN5";
export var quotationIllustration = "JoinLeadersTestimonials-module--quotationIllustration--a6AXE";
export var slickDotsBottom = "JoinLeadersTestimonials-module--slick-dots-bottom--Bb7Cb";
export var slickDotsBottomX = "JoinLeadersTestimonials-module--slickDotsBottomX--v6TMk";
export var testimonial = "JoinLeadersTestimonials-module--testimonial--8lUly";
export var testimonialAuthor = "JoinLeadersTestimonials-module--testimonialAuthor--lEOrW";
export var testimonialContainer = "JoinLeadersTestimonials-module--testimonialContainer--3anzP";
export var testimonialText = "JoinLeadersTestimonials-module--testimonialText--W3qg-";
export var widthOnLargeScreensClass = "JoinLeadersTestimonials-module--widthOnLargeScreensClass--55G4A";