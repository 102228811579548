import React from "react";
import { AutoComplete, Carousel } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { PageMargins, TypographyV2 } from "@components";
import { DemoButtonWithInput } from "@components/Button_new/Button";
import * as styles from "./JoinLeadersTestimonials.module.scss";
import classNames from "classnames";
type Testimonial = {
	quote:string;
	authorTitle: string;
	author: string
}

export const JoinLeadersTestimonials = ({testimonials, joinInstitutionContainerClass, joinInstitutionBodyClass,inputWrapperOverwrite,testimonialOverwrite}: {testimonials? :Testimonial[], joinInstitutionContainerClass?: string, joinInstitutionBodyClass?: string, inputWrapperOverwrite?:string, testimonialOverwrite?:string}) => {
	return (
		<PageMargins widthOnLargeScreensClass={styles.widthOnLargeScreensClass} className={styles.generalLayoutContainer}>
			<div className={classNames(styles.joinInstitutionContainer, joinInstitutionContainerClass)}>
				<div className={classNames(styles.joinInstitution, joinInstitutionBodyClass )}>
					<TypographyV2
						variant="HEADING_3"
						tabletVariant="HEADING_3"
						mobileVariant="HEADING_3"
						weight="MEDIUM"
						color="text-primary"
						className={styles.headerText}
					>
						Join thousands of industry leaders in scientific
						research
					</TypographyV2>

					<DemoButtonWithInput
						emailInputClass={styles.emailInputClass}
						emailInputLabelClass={styles.emailInputLabelClass}
						wideInput
						placeholder="email@company.com"
						mobileFullWidth={false}
						emailInputWrapperClass={styles.emailInputWrapperClass}
						inputWrapperOverwrite={inputWrapperOverwrite}
                        buttonLinkClass={styles.buttonLinkClass}
						lightmode
					/>
				</div>
			</div>

			<div className={styles.testimonialContainer}>
				<div className={styles.quotationIllustration}>
					<QuotationIllustration />
				</div>
				<CarouselApp className="joinLeadersTestimonials" testimonialOverwrite={testimonialOverwrite} testimonials={testimonials ? testimonials : DefaultCustomerQuotes} />
			</div>
		</PageMargins>
	);
};

export const QuotationIllustration = () => (
	<StaticImage
		loading="eager"
		src="./_assets/quotation-illustration.png"
		alt="Notebook"
	/>
);

export const AuthorLine = () => (
	<StaticImage loading="eager" src="./_assets/line.png" alt="Notebook" />
);
const contentStyle = {
	margin: 0,
	height: "160px",
	color: "#fff",
	lineHeight: "160px",
	textAlign: "center",
	background: "#364d79",
};
const CarouselApp = ({ className = "", testimonialOverwrite, testimonials }: { className?: string,testimonialOverwrite?:string, testimonials? :Testimonial[] }) => {
	return (
		<div style={{ width: "100%" }}>
			<Carousel className={className} dots>
				{testimonials && testimonials.map(({quote,authorTitle,author}, index)=> 	<TestimonialCard
					quote={quote}
					author={author}
					authorTitle={authorTitle}
					key={index}
					testimonialOverwrite={testimonialOverwrite??''}
				/> )}
			</Carousel>
		</div>
	);
};

const TestimonialCard = ({
	author,
	authorTitle,
	quote,
	testimonialOverwrite
}: {
	quote: string;
	author: string;
	authorTitle: string;
	testimonialOverwrite:string;
}) => {
	return (
		<div>
			<div className={classNames(styles.testimonial, testimonialOverwrite)}>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					tabletVariant="BODY_TEXT_EXTRA_LARGE"
					mobileVariant="BODY_TEXT_EXTRA_LARGE"
					weight="REGULAR"
					className={styles.testimonialText}
				>
					{quote}
				</TypographyV2>
				<div className={styles.testimonialAuthor}>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						tabletVariant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="SEMIBOLD"
						color="brand-500"
						className={styles.authorName}
					>
						{author}
						<div className={styles.authorLine}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="2"
								height="27"
								viewBox="0 0 2 27"
								fill="none"
							>
								<path
									d="M1 1.5L0.999999 25.5"
									stroke="#FEEBDD"
									stroke-width="2"
									stroke-linecap="round"
								/>
							</svg>
						</div>
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						tabletVariant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="SEMIBOLD"
						color="brand-500"
						className={styles.authorTitle}

					>
						{authorTitle}
					</TypographyV2>
				</div>
			</div>
		</div>
	);
};

export default JoinLeadersTestimonials;


const DefaultCustomerQuotes = [
	{
	quote:"User-friendly interface, powerful features, and commitment to security and compliance make it a standout choice in the competitive landscape of laboratory software.",
	author:"Bhavya V.",
	authorTitle:"Enterprise (> 1000 emp.)"
},
{

	quote:`“The software's focus on workflow optimization, data management, and collaboration sets it apart in an industry where precision and efficiency are paramount.”`,
	author:"Robbie A.",
	authorTitle:"Small-Business (> 50 emp.)"
},
{
		quote:`“Genemod provides different useful features, which are all integratabtle with each other, increasing productivity tremendously. The quick and friendly customer support gives feedback or helps whenever requested.”`,
		author:"Arno C.",
		authorTitle:"Small-Business (> 50 emp.)"

}
]